.demo_image {
    border-radius: 50%;
    z-index: 11;
    padding: 3px !important;
    margin-top: 3px !important;
    margin-left: 0px !important;
    object-fit: cover !important;
}


.mockup3 {
    border-radius: 10px;
    box-shadow: 1px 2px 20px #0d2406;
    overflow: unset;
}

.editorMockup {
    overflow: unset;
}


.editorPreview {
    border-radius: 20px;
    border: 10px solid black;
    box-shadow: 1px 2px 10px #b1b1b1;
    overflow: unset;
}


/* .host_image {
    transform:scale(1.15);
    -ms-transform:scale(1.15); 
    -moz-transform:scale(1.15); 
    -webkit-transform:scale(1.15); 
    -o-transform:scale(1.15);
    object-position: -40px 20px;
    overflow: hidden;
    
} */